import React from "react"
import Slider from "./slider"
import ServiceBlock from "./ServiceBlock"
import Whoweare from "../home/who-we-are"

import Letstalk from "../home/letstalk"
import WorkWithUs from "../home/work-with-us"

const homePage = () => {
  return (
    <>
      <Slider />
      <ServiceBlock />
      <Whoweare />
      <Letstalk />
      <WorkWithUs />
    </>
  )
}

export default homePage
