import React from "react"

import { useStaticQuery, Link, graphql } from "gatsby"

const ServiceBlock = () => {
  const data = useStaticQuery(graphql`
    query {
      allMysqlServices {
        nodes {
          id
          mysqlId
          category_id
          title
          description
          image_url1
          slug
        }
      }
    }
  `)
  const servicesArray = data.allMysqlServices.nodes
  console.log(servicesArray)

  return (
    <>
      <div className="rs-services services-style13 gray-bg6 pt-120 pb-120 md-pt-80 md-pb-80 pt-4">
        <div className="container">
          <div className="row">
            {servicesArray.map((service, index) => {
              return (
                <div className="col-xl-4 col-md-6 mb-20 py-4" key={index}>
                  <div className="services-item">
                    <div className="serial-number">{service.mysqlId}</div>
                    <div className="services-icon">
                      <img src={service.image_url1} alt="Images" />
                    </div>
                    <div className="services-text">
                      <h4 className="title">
                        <Link to={service.slug}>{service.title}</Link>
                      </h4>
                      <p className="services-txt">
                        {service.description.split(" ").splice(0, 15).join(" ")}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceBlock
