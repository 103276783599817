import React from "react"

import Layout from "../components/layout"
import ServicesPage from "../components/services/index"

import Seo from "../components/seo"

const servicesPage = () => (
  <Layout>
    <Seo title="Bradiance" desc="Bradiance" keywords="Bradiance" />
    <ServicesPage />
  </Layout>
)

export default servicesPage
